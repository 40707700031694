<template>
    <div class="m-grid m-grid--hor m-grid--root m-page">
        <div class="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--singin m-login--2 m-login-2--skin-2"
             id="m_login" :style="background_image">
            <div class="m-grid__item m-grid__item--fluid	m-login__wrapper">
                <div class="m-login__container">
                    <div class="m-login__logo">
                        <img width="200" height="200" src="@assets/images/lukoil-logo-rus.svg"/>
                    </div>
                    <div class="m-login__signin">
                        <div class="m-login__head">
                            <h3 class="m-login__title">
                                Вход в систему
                            </h3>
                        </div>
                        <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }" >
                            <form @submit.prevent="handleSubmit(login)" class="m-login__form m-form" action="" novalidate>
                                <div class="form-group m-form__group">
                                    <ValidationProvider name="login" rules="required" v-slot="{ errors }">
                                        <input v-model="username" class="form-control m-input" type="text" placeholder="Логин"
                                               name="username"
                                               autocomplete="off" required>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group m-form__group">
                                    <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                                        <input v-model="password" class="form-control m-input m-login__form-input--last" type="password"
                                               placeholder="Пароль" name="password" required maxlength="32">
                                    </ValidationProvider>
                                </div>
                                <div class="m-login__form-action">
                                    <button id="m_login_signin_submit"
                                            class="btn btn-danger m-btn m-btn--pill m-btn--custom m-btn--air  m-login__btn m-login__btn--primary"
                                            :disabled="invalid || untouched"
                                    >
                                        Войти
                                    </button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate';
    import {mapActions} from 'vuex'

    export default {
        name: 'Login',
        components: {
            ValidationObserver,
            ValidationProvider
        },
        data () {
            return {
                username: '',
                password: ''
            }
        },
        methods: {
            ...mapActions('account', ['authenticate']),
            async login() {
                await this.authenticate({
                    username: this.username,
                    password: this.password
                });
            }
        },
        computed: {
            background_image() {
                return {
                    'background-image': `url(${require('~/assets/images/login-bg.jpg')});`
                };
            }
        }
    }
</script>

<style lang="sass">
    @import '@assets/sass/pages/login.sass'
</style>
